/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    if (!window.location.origin) {
        window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                this.menu();
                this.mobileMenu();
                this.cookieBar();
            },
            cookieBar: function(){
                localStorage.setItem('cookie-confirmed', 0);
                $('.cookie-confirm').on('click', function(e){
                    localStorage.setItem('cookieconfirmed', 1);
                    $('.cookie-confirmation').hide();
                });
                
                if(localStorage.getItem('cookieconfirmed') > 0){
                    $('.cookie-confirmation').hide();
                }
            },
            menu: function () {
                $('.desktop-menu a[href="/"]').click(function (e) {
                    e.preventDefault();
                });
            },
            mobileMenu: function () {
                $('button.toggle-menu').click(function () {
                    $(this).toggleClass('in');
                    $('.mobile-menu').toggleClass('in');
                    if ($('.mobile-menu').hasClass('in')) {
                        $('body').addClass('stop-scroll');
                    } else {
                        $('body').removeClass('stop-scroll');
                    }
                });

                var saveMenu;
                var pager;
                var mobileMenuUl;

                $('.mobile-menu .pager').hide();
                $('.mobile-menu a[href="/"]').click(function (e) {
                    e.preventDefault();
                    $('.menu-item').hide();
                    var parentLi = $(this).parent();
                    parentLi.show();
                    $(this).hide();

                    var submenu = parentLi.children('.sub-menu');
                    submenu.show();
                    submenu.children('.menu-item').show();
                    $('.mobile-menu .pager').toggle();
                });

                $('.mobile-menu .pager a').on('click', function (e) {
                    e.preventDefault();
                    $('.menu-item').show();
                    $('.menu-item .sub-menu').hide();
                    $(this).parent().toggle();
                    $('a[href="/"]').show();
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
